import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getActualiteById, getRecentActualites } from '../../slices/actualite/ActualiteSlice';
import Sidebar from './sidebar';
import { actGetPopularProperties } from '../../slices/appartement/AppartSlice';

function BlogDetails() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const actualite = useSelector(state => state.actualites.items.find(item => item?._id === id));
	const recentActualites = useSelector(state => state.actualites.recentItems) || [];
	const loading = useSelector(state => state.actualites.status === 'loading');
	const error = useSelector(state => state.actualites.error);
	let publicUrl = process.env.PUBLIC_URL + '/';

	useEffect(() => {
		if (id) {
			dispatch(getActualiteById(id));
		}
		dispatch(getRecentActualites());
		dispatch(actGetPopularProperties());
	}, [dispatch, id]);

	if (!id) return <div>ID invalide.</div>;
	if (loading) return <div>Chargement...</div>;
	if (error) return <div>Erreur : {error}</div>;
	if (!actualite) return <div>Aucune actualité trouvée.</div>;

	// Find the index of the current article in the recentActualites array
	const currentIndex = recentActualites.findIndex(item => item?._id === id);

	// Get the previous and next articles
	const previousArticle = currentIndex > 0 ? recentActualites[currentIndex - 1] : null;
	const nextArticle = currentIndex < recentActualites.length - 1 ? recentActualites[currentIndex + 1] : null;

	// Get two related articles (excluding the current one)
	const relatedArticles = recentActualites
		.filter(item => item?._id !== id)
		.slice(0, 2);

	return (
		<div className="ltn__page-details-area ltn__blog-details-area mb-120">
			<div className="container">
				<div className="row">
					<div className="col-lg-8">
						<div className="ltn__blog-details-wrap">
							<div className="ltn__page-details-inner ltn__blog-details-inner">
								<div className="ltn__blog-meta">
								</div>
								<h2 className="ltn__blog-title">{actualite.title}</h2>
								<div className="ltn__blog-meta">
									<ul>
										<li className="ltn__blog-author go-top">
											<Link to="/team-details"><img src={actualite.image} alt="#" />Par : {actualite.author}</Link>
										</li>
										<li className="ltn__blog-date">
											<i className="far fa-calendar-alt" />{new Date(actualite.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })}
										</li>
									</ul>
								</div>
								<p>{actualite.description}</p>
								<img src={actualite.image} alt="Image" />
								{actualite.title1 && <h2>{actualite.title1}</h2>}
								{actualite.description1 && <p>{actualite.description1}</p>}
								{actualite.title2 && <h2>{actualite.title2}</h2>}
								{actualite.description2 && <p>{actualite.description2}</p>}
								{actualite.title3 && <h2>{actualite.title3}</h2>}
								{actualite.description3 && <p>{actualite.description3}</p>}
								{actualite.title4 && <h2>{actualite.title4}</h2>}
								{actualite.description4 && <p>{actualite.description4}</p>}
								<hr />
							</div>
							<div className="ltn__blog-tags-social-media mt-80 row">
								<div className="ltn__tagcloud-widget col-lg-8 go-top">
									<h4>Tags Associés</h4>
									<ul>
										<li>
											<Link to="/shop">Populaire</Link>
										</li>
										<li>
											<Link to="/shop">Affaires</Link>
										</li>
										<li>
											<Link to="/shop">UX</Link>
										</li>
									</ul>
								</div>
								<div className="ltn__social-media text-right text-end col-lg-4">
									<h4>Partager sur</h4>
									<ul>
										<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
										<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
										<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
										<li><a href="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
									</ul>
								</div>
							</div>
							<hr />
							{recentActualites && recentActualites.length > 0 && (
								<>
									<div className="ltn__prev-next-btn row mb-50">
										<div className="blog-prev col-lg-6">
											<h6>Article Précédent</h6>
											{previousArticle ? (
												<h3 className="ltn__blog-title">
													<Link to={`/blog-details/${previousArticle._id}`}>{previousArticle.title}</Link>
												</h3>
											) : (
												<p>Pas d'article précédent</p>
											)}
										</div>
										<div className="blog-prev blog-next text-right text-end col-lg-6">
											<h6>Article Suivant</h6>
											{nextArticle ? (
												<h3 className="ltn__blog-title">
													<Link to={`/blog-details/${nextArticle._id}`}>{nextArticle.title}</Link>
												</h3>
											) : (
												<p>Pas d'article suivant</p>
											)}
										</div>
									</div>
									<hr />
									{relatedArticles.length > 0 && (
										<div className="related-post-area mb-50">
											<h4 className="title-2">Articles Connexes</h4>
											<div className="row">
												{relatedArticles.map((article, index) => (
													<div className="col-md-6" key={article._id}>
														<div className="ltn__blog-item ltn__blog-item-6">
															<div className="ltn__blog-img">
																<Link to={`/blog-details/${article._id}`}>
																	<img src={article.image || publicUrl + "assets/img/blog/blog-details/11.jpg"} alt={article.title} />
																</Link>
															</div>
															<div className="ltn__blog-brief">
																<div className="ltn__blog-meta">
																	<ul>
																		<li className="ltn__blog-date ltn__secondary-color">
																			<i className="far fa-calendar-alt" />
																			{new Date(article.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })}
																		</li>
																	</ul>
																</div>
																<h3 className="ltn__blog-title">
																	<Link to={`/blog-details/${article._id}`}>{article.title}</Link>
																</h3>
																<p>{article.description?.substring(0, 100)}...</p>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									)}
								</>
							)}
							{(!recentActualites || recentActualites.length === 0) && (
								<p>Aucun article récent disponible.</p>
							)}
						</div>
					</div>
					<Sidebar />
				</div>
			</div>
		</div>
	);
}

export default BlogDetails;