import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  fetchReviewById,
  createReview,
  getReviewsByAppartmentId,
  fetchTopRatedReviews
} from './ReviewActions';


const initialState = {
  items: [], // Pour stocker plusieurs reviews
  loading: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  item: {}, // Pour stocker une review unique
  itemsA: [], // Pour stocker plusieurs reviews par appartement
  topRated: [], // Pour stocker les appartements mieux notés
};

const reviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    resetUI: (state) => {
      state.itemsA = [];
      state.loading = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Top Rated Reviews
      .addCase(fetchTopRatedReviews.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchTopRatedReviews.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.topRated = action.payload;
      })
      .addCase(fetchTopRatedReviews.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload;
      })

      // Fetch review by ID
      .addCase(fetchReviewById.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchReviewById.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.item = action.payload;
      })
      .addCase(fetchReviewById.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Create a new review
      .addCase(createReview.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.items.push(action.payload);
      })
      .addCase(createReview.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Get reviews by apartment ID
      .addCase(getReviewsByAppartmentId.pending, (state) => {
        state.loading = 'loading';
        state.error = null;
      })
      .addCase(getReviewsByAppartmentId.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.itemsA = action.payload;
      })
      .addCase(getReviewsByAppartmentId.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export default reviewSlice.reducer;
export { fetchReviewById, createReview, getReviewsByAppartmentId, fetchTopRatedReviews };
export const { resetUI } = reviewSlice.actions;
