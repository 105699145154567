import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import keylockIcon from '../../img/icons/keylock.svg';

const keylockStyle = {
	transform: 'translateY(-10%)',
	cursor: 'pointer',
	height: "62px",
	fill: "white",
	padding: "12px",
};

const CopyRight = () => {
	const history = useHistory();
	let currentYear = new Date().getFullYear();

	const redirectToLink = () => {
		history.push('/login');
	};

	return (
		<div className="ltn__copyright-area ltn__copyright-2 section-bg-7 plr--5">
			<div className="container-fluid ltn__border-top-2">
				<div className="row">
					<div className="col-md-6 col-12">
						<div className="ltn__copyright-design clearfix">
							<p>All Rights Reserved @ AZ HUB <span className="current-year">{currentYear}</span></p>
						</div>
					</div>
					<div className="col-md-6 col-12 align-self-center">
						<div className="ltn__copyright-menu text-end">
							<ul className="go-top">
								<li>
									<img
										src={keylockIcon}
										alt="Keylock"
										style={keylockStyle}
										onClick={redirectToLink}
									/>
								</li>
								<li><Link to="/about">Terms &amp; Conditions</Link></li>
								<li><Link to="/about">Claim</Link></li>
								<li><Link to="/about">Privacy &amp; Policy</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CopyRight;