import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination, Snackbar, Alert } from '@mui/material';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { actGetAppartements, actDeleteAppartement } from '../../slices/appartement/AppartActions';
import Navbar from '../global-components/navbar';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useAlert } from 'react-alert'; // Importer react-alert

const DashboardAnnonces = () => {
    const dispatch = useDispatch();
    const { records, loading, error } = useSelector(state => state.appartement);
    const alert = useAlert();  // Initialiser useAlert
    const [formData, setFormData] = useState({});

    // States for pagination and deletion confirmation
    const [page, setPage] = useState(1);  // Page actuelle
    const [pageSize, setPageSize] = useState(10);  // Nombre de lignes par page
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedAppartementId, setSelectedAppartementId] = useState(null);

    // States for Snackbar alerts
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(actGetAppartements());
    }, [dispatch]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <div>Erreur: {error}</div>;
    }

    if (!records || records.length === 0) {
        return <div>Aucune annonce trouvée.</div>;
    }

    // Handle deletion with confirmation dialog
    const handleDeleteClick = (id) => {
        setSelectedAppartementId(id);
        setOpenDialog(true);
    };

    const handleDeleteConfirm = () => {
        if (selectedAppartementId) {
            dispatch(actDeleteAppartement(selectedAppartementId))
                .then(() => {
                    setSnackbarMessage('Appartement supprimé avec succès.');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);

                    // Relancer la requête pour récupérer la liste des appartements
                    dispatch(actGetAppartements());

                    setOpenDialog(false);
                    setSelectedAppartementId(null);
                })
                .catch(() => {
                    setSnackbarMessage('Erreur lors de la suppression.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    setOpenDialog(false);
                    setSelectedAppartementId(null);
                });
        }
    };


    const handleDeleteCancel = () => {
        setOpenDialog(false);
        setSelectedAppartementId(null);
    };

    // Close Snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };




    // Calculer les données affichées en fonction de la page actuelle
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = records.slice(startIndex, endIndex);

    // Pagination Controls
    const totalPages = Math.ceil(records.length / pageSize);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // Columns for the DataGrid
    const columns = [
        { field: '_id', headerName: 'ID', flex: 1, minWidth: 150 },
        { field: 'title', headerName: 'Titre', flex: 2, minWidth: 200 },
        { field: 'category', headerName: 'Catégorie', flex: 1, minWidth: 120 },
        {
            field: 'price',
            headerName: 'Prix',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <span>
                    {params.row.price
                        ? `${params.row.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €`
                        : 'Prix non disponible'}
                </span>
            )
        }, { field: 'adress', headerName: 'Adresse', flex: 1, minWidth: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <IconButton
                        component={Link}
                        to={`/update-listing/${params.row._id}`}
                        color="primary"
                        size="small"
                    >
                        <EditOutlined style={{ fontSize: '20px' }} />
                    </IconButton>
                    <IconButton
                        onClick={() => handleDeleteClick(params.row._id)}
                        color="error"
                        size="small"
                    >
                        <DeleteOutlined style={{ fontSize: '20px' }} />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <div>
            {/* Navbar */}
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="container mt-4">
                <div className="d-flex align-items-center mb-4">
                    {/* Title with Vertical Line */}
                    <h2 className="text-left" style={{ marginRight: 'auto', display: 'flex', alignItems: 'center' }}>
                        Gestion des annonces
                        {/* Vertical line */}
                        <span style={{
                            borderLeft: '2px solid #304798',
                            height: '30px',
                            marginLeft: '-350px'
                        }}></span>
                    </h2>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/add-listing"
                        style={{ backgroundColor: '#304798', color: 'white', marginLeft: '70px' }}
                    >
                        Ajouter une annonce
                    </Button>
                </div>

                <Box m="20px" height="70vh">
                    <DataGrid
                        rows={currentData}  // Utiliser les données paginées
                        columns={columns}
                        pageSize={pageSize}  // Limite à 5 lignes par page
                        getRowId={(row) => row._id}  // Assurez-vous que la clé unique est bien `_id`
                        disableSelectionOnClick
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                color: '#84a2a6',
                                fontSize: '16px',
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: 'white',
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: '#304798',
                                color: "white",
                                paddingBottom: '20px',  // Espace supplémentaire en bas

                            },
                            "& .MuiPaginationItem-root": {
                                color: 'white',

                            },
                            "& .MuiTablePagination-toolbar": {
                                display: 'none',  // Cache le texte de pagination
                                paddingBottom: '20px',  // Espace supplémentaire en bas

                            },
                        }}
                    />

                    {/* Custom Pagination */}
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', paddingBottom: '60px' }}
                    />
                </Box>
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmer la suppression"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir supprimer cet appartement ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for success/error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DashboardAnnonces;

