import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Testimonial extends Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70" data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nos Témoignages</h6>
							<h1 className="section-title">Avis de Clients</h1>
						</div>
					</div>
				</div>
				<div className="row ltn__testimonial-slider-5-active slick-arrow-1">
					<div className="col-lg-4">
						<div className="ltn__testimonial-item ltn__testimonial-item-7">
							<div className="ltn__testimoni-info">
								<p><i className="flaticon-left-quote-1" />
									L'accompagnement de cette agence a été
									exceptionnel dans notre recherche de maison.
									Leur expertises du marché local ont fait toute la différence.
									Grâce à eux, nous avons trouvé la propriété
									idéale qui correspond parfaitement à nos attentes.
									Un grand merci pour leur professionnalisme et leur patience.</p>
								<div className="ltn__testimoni-info-inner">
									<div className="ltn__testimoni-img">
										<img src={publicUrl + "assets/img/testimonial/ta3.jpg"} alt="#" />
									</div>
									<div className="ltn__testimoni-name-designation">
										<h5>Sophie Martin</h5>
										<label>Enseignante</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__testimonial-item ltn__testimonial-item-7">
							<div className="ltn__testimoni-info">
								<p><i className="flaticon-left-quote-1" />
									En tant qu'investisseur immobilier, j'ai
									travaillé avec plusieurs agences, mais
									celle-ci se démarque vraiment. Leur analyse
									approfondie du potentiel locatif et leur
									connaissance des tendances du marché m'ont
									permis de faire des choix éclairés. Leur
									service après-vente est également remarquable.</p>
								<div className="ltn__testimoni-info-inner">
									<div className="ltn__testimoni-img">
										<img src={publicUrl + "assets/img/testimonial/ta2.jpg"} alt="#" />
									</div>
									<div className="ltn__testimoni-name-designation">
										<h5>Alexandre Dubois</h5>
										<label>Entrepreneur</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__testimonial-item ltn__testimonial-item-7">
							<div className="ltn__testimoni-info">
								<p><i className="flaticon-left-quote-1" />
									La vente de notre appartement familial
									était une décision émotionnelle difficile.
									L'agence a fait preuve d'une grande empathie
									et d'un vrai professionnalisme tout au long
									du processus. Leur stratégie de marketing
									ciblée a attiré rapidement des acheteurs
									sérieux. Nous sommes très satisfaits du résultat.</p>
								<div className="ltn__testimoni-info-inner">
									<div className="ltn__testimoni-img">
										<img src={publicUrl + "assets/img/testimonial/ta1.jpg"} alt="#" />
									</div>
									<div className="ltn__testimoni-name-designation">
										<h5>Jordan Lefevre</h5>
										<label>Avocat</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__testimonial-item ltn__testimonial-item-7">
							<div className="ltn__testimoni-info">
								<p><i className="flaticon-left-quote-1" />
									Pour notre premier achat immobilier,
									nous étions un peu perdus. L'équipe de
									l'agence nous a guidés étape par étape,
									expliquant clairement chaque aspect du
									processus. Leur réseau de partenaires
									(notaires, banques) a grandement facilité
									nos démarches. Une expérience vraiment positive !</p>
								<div className="ltn__testimoni-info-inner">
									<div className="ltn__testimoni-img">
										<img src={publicUrl + "assets/img/testimonial/ta4.jpg"} alt="#" />
									</div>
									<div className="ltn__testimoni-name-designation">
										<h5>Thomas et Léa Rousseau</h5>
										<label>Jeune couple</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default Testimonial