import { createAsyncThunk } from '@reduxjs/toolkit';
// import { BASE_URL } from '../../constants/Config';
import axios from 'axios';
const BASE_URL = "/api"

export const submitActualites = createAsyncThunk(
  'actualites/submit',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/actualites`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Could not submit actualites.');
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getActualites = createAsyncThunk(
  'actualites/get',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/actualites`, {
        method: 'GET',
        body: formData,
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Could not get actualites.');
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);





export const getActualiteById = createAsyncThunk(
  'actualites/getById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/actualites/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || `Could not get actualite with ID: ${id}`);
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getRecentActualites = createAsyncThunk(
  'actualites/getRecent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/actualites/recent`);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Could not get recent actualites.');
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const actDeleteActualite = createAsyncThunk(
  'actualites/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/actualites/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || `Could not delete actualite with ID: ${id}`);
      return id;  // Return the ID of the deleted actualite so we can update the state
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const actUpdateActualite = createAsyncThunk(
  'actualites/update',
  async ({ id, formData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.put(`${BASE_URL}/actualites/${id}`, formData);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.message || error.message);
      } else {
        return rejectWithValue("I Think Something went wrong!");
      }
    }
  }
);
