import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { actLogin } from '../../slices/Auth/AuthSlices';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

const Login = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});
	const history = useHistory();
	const alert = useAlert();

	// Get loading and auth state from the Redux store
	const loading = useSelector(state => state.auth.loading);
	const token = localStorage.getItem('token');

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(actLogin(formData)); // Dispatch the login action
	};

	// Use useEffect to redirect the user when login is successful (i.e., token is available)
	useEffect(() => {
		if (token) {
			history.push('/dashboard-annonces'); // Redirect to dashboard if login is successful
		}
	}, [token, history]); // Dependency array to trigger when token changes

	return (
		<div>
			<div className="ltn__login-area pb-65">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title-area text-center">
								<h1 className="section-title">Sign In <br />To  Your Account</h1>
								<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. <br />
									Sit aliquid,  Non distinctio vel iste.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="account-login-inner">
								<form onSubmit={handleSubmit} className="ltn__form-box contact-form-box">
									<input type="text" name="email" placeholder="Email*" onChange={handleChange} />
									<input type="password" name="password" placeholder="Password*" onChange={handleChange} />
									<div className="btn-wrapper mt-0">
										<button className="theme-btn-1 btn btn-block" type="submit">
											{loading === 'pending' ? 'Loading...' : 'SIGN IN'}
										</button>
									</div>
									<div className="go-to-btn mt-20">
										<a href="#" title="Forgot Password?" data-bs-toggle="modal" data-bs-target="#ltn_forget_password_modal"><small>FORGOTTEN YOUR PASSWORD?</small></a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
