import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getActualites } from '../../slices/actualite/ActualiteActions';

const BlogGrid = () => {
	const dispatch = useDispatch();
	const { items, status, error } = useSelector((state) => state.actualites);

	// Pagination state
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 6;

	useEffect(() => {
		dispatch(getActualites());
	}, [dispatch]);

	if (status === 'loading') {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	const sortedItems = [...items].sort((a, b) => new Date(b.date) - new Date(a.date));
	const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

	// Get current items for the page
	const currentItems = sortedItems.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	// Handle page change
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
			<div className="container">
				<div className="row">
					{currentItems.map((actualite) => (
						<div className="col-lg-4 col-sm-6 col-12" key={actualite._id}>
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to={`/blog-details/${actualite._id}`}>
										<img src={actualite.image} alt={actualite.title} />
									</Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<Link to="/team-details"><i className="far fa-user" />by: {actualite.author}</Link>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title">
										<Link to={`/blog-details/${actualite._id}`}>{actualite.title}</Link>
									</h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<i className="far fa-calendar-alt" />
													{new Date(actualite.date).toLocaleDateString()}
												</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to={`/blog-details/${actualite._id}`}>Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				{/* Pagination */}
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__pagination-area text-center">
							<div className="ltn__pagination">
								<ul>
									<li>
										<Link
											to="#"
											onClick={() => handlePageChange(currentPage - 1)}
											disabled={currentPage === 1}
										>
											<i className="fas fa-angle-double-left" />
										</Link>
									</li>
									{[...Array(totalPages).keys()].map((pageNumber) => (
										<li key={pageNumber + 1}>
											<Link
												to="#"
												className={currentPage === pageNumber + 1 ? 'active' : ''}
												onClick={() => handlePageChange(pageNumber + 1)}
											>
												{pageNumber + 1}
											</Link>
										</li>
									))}
									<li>
										<Link
											to="#"
											onClick={() => handlePageChange(currentPage + 1)}
											disabled={currentPage === totalPages}
										>
											<i className="fas fa-angle-double-right" />
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogGrid;