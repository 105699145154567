import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import emailjs from '@emailjs/browser';
import { actGetAppartement, actGetPropertyCounts, actGetPopularProperties, actfindNearbyApartments } from '../../slices/appartement/AppartSlice';
import Map from "../../components/section-components/maps";
import ReactPlayer from 'react-player';
import { getReviewsByAppartmentId, createReview, resetUI, fetchTopRatedReviews } from '../../slices/review/ReviewSlice';
import StarRating from './StarRating';
import { useAlert } from 'react-alert';

const ShopDetails = () => {
    const { id: productId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { record: product, loading, error } = useSelector(state => state.appartement);
    const [propertyCounts, setPropertyCounts] = useState({});
    const [popularProperties, setPopularProperties] = useState([]);
    const [coordinates, setCoordinates] = useState(null);
    const form = useRef();

    const [emailStatus, setEmailStatus] = useState(""); // État pour le message de confirmation
    const [reviews, setReviews] = useState({
        appartment_id: '',
        rating: '',
        email: '',
        comment: '',
        fullname: ''
    });
    const [rating, setRating] = useState(0);
    const reviewItems = useSelector((state) => state.reviews.itemsA.Reviews);
    const alert = useAlert();
    const [nearbyProperties, setNearbyProperties] = useState([]);
    const topRatedApartments = useSelector((state) => state.reviews.topRated);

    useEffect(() => {
        dispatch(actGetAppartement(productId))
            .then(response => {
                if (response.payload && response.payload.geoLocation) {
                    setCoordinates(response.payload.geoLocation.coordinates);
                }
                if (response.error) {
                    console.error("API Error:", response.error);
                }
            })
            .catch(err => {
                console.error("Failed to fetch property details:", err);
            });

        // Fetch top-rated apartments
        dispatch(fetchTopRatedReviews())
            .then(response => {
                if (response.error) {
                    console.error("Failed to fetch top-rated apartments:", response.error);
                } else {
                    console.log("Top Rated Apartments:", response.payload);  // Ajouter cette ligne
                }
            })
            .catch(err => console.error("Failed to fetch top-rated apartments:", err));

        dispatch(actGetPopularProperties())
            .then(response => setPopularProperties(response.payload.filter(property => property.views > 0)))
            .catch(err => setPopularProperties([]));

        dispatch(actGetPropertyCounts())
            .then(response => setPropertyCounts(response.payload))
            .catch(err => console.error("Failed to fetch property counts:", err));

        dispatch(actfindNearbyApartments(productId))
            .then(response => { setNearbyProperties(response.payload); })
            .catch(err => console.error("Failed to fetch nearby properties:", err));

    }, [dispatch, productId]);

    useEffect(() => {
        // Reset reviews when component mounts or productId changes
        dispatch(resetUI());

        // Fetch reviews for the current product
        dispatch(getReviewsByAppartmentId(productId))
            .catch(err => console.error("Failed to fetch reviews:", err));

        // Cleanup function to reset reviews when component unmounts
        return () => {
            dispatch(resetUI());
        };
    }, [dispatch, productId]);

    if (!product || !product.geoLocation || !product.geoLocation.coordinates) {
        return <div>Coordonnées de localisation non disponibles</div>;
    }

    const sendEmail = async (e) => {
        e.preventDefault();

        emailjs.sendForm("service_v1afctl", "template_ytp08gz", form.current, {
            publicKey: "5nSvvdSIRS4sqijml",
        })
            .then(
                () => {
                    history.push('/confirmation', { message: "Votre message a été envoyé avec succès." }); // Redirection vers la page de confirmation
                },
                (error) => {
                    setEmailStatus("Échec de l'envoi du message. Veuillez réessayer."); // Message d'erreur en cas d'échec
                    console.log("EmailJS Error: ", error.text);
                }
            );
    };
    const getYouTubeThumbnail = (url) => {
        if (!url || typeof url !== 'string') {
            return ''; // retourne une chaîne vide si l'URL n'est pas définie ou n'est pas une chaîne
        }

        const videoId = url.split('v=')[1]?.split('&')[0];

        if (!videoId) {
            return ''; // retourne une chaîne vide si l'ID de la vidéo ne peut pas être extrait
        }

        return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    };

    const handleCommentFormChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        setReviews({
            ...reviews,
            [name]: value
        });
    };


    const handleCommentSubmit = (e) => {
        e.preventDefault();
        dispatch(createReview({
            appartment_id: productId,
            rating: rating,
            email: e.target.email.value,
            comment: e.target.comment.value,
            fullname: e.target.fullname.value
        }))
            .then(() => {
                alert.success('Commentaire ajouté avec succés', { type: 'success' });
                e.target.reset();
                setRating(0);
            })
            .catch((err) => {
                console.error("Failed to submit comment:", err);
                alert.error(`Error: ${error.message || 'Unknown error'}`);
            });
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!product) return <div>Produit non trouvé</div>;

    let publicUrl = process.env.PUBLIC_URL + '/';

    const mapContainerStyles = {
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
        marginBottom: '30px',
    };

    return (
        <div className="ltn__shop-details-area pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <div className="ltn__blog-meta">
                                <ul>
                                    <li className="ltn__blog-category">
                                        <Link to="#">{product.type === "Vente" ? "À Vendre" : "À Louer"}</Link>
                                    </li>
                                    <li className="ltn__blog-category">
                                        <Link className="bg-orange" to="#">{product.status}</Link>
                                    </li>
                                    <li className="ltn__blog-date">
                                        <i className="far fa-calendar-alt" />{new Date(product.createdAt).toLocaleDateString()}
                                    </li>
                                    <li>
                                        <Link to="#"><i className="far fa-comments" />{product.comments || 0} Commentaires</Link>
                                    </li>
                                </ul>
                            </div>
                            <h1>{product.title}</h1>
                            <label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> {product.quartier}</label>
                            <h4 className="title-2">Description</h4>
                            <p>{product.description}</p>

                            <h4 className="title-2">Détails de la Propriété</h4>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul>
                                    <li><label>ID de la Propriété:</label> <span></span></li>
                                    <li><label>Surface Habitable:</label> <span>{product.surface} m²</span></li>
                                    <li><label>Pièces:</label> <span>{product.rooms}</span></li>
                                    <li><label>Salles de Bain:</label> <span>{product.bathrooms}</span></li>
                                    <li><label>Année de Construction:</label> <span>{product.yearBuilt}</span></li>
                                </ul>
                                <ul>
                                    <li><label>Chambres:</label> <span>{product.bedrooms}</span></li>
                                    <li><label>Prix:</label> <span>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €</span></li>
                                    <li><label>Statut de la Propriété:</label> <span>{product.status}</span></li>
                                </ul>
                            </div>

                            <h4 className="title-2">Caractéristiques et Équipements</h4>
                            <div className="property-detail-feature-list clearfix mb-45">
                                <ul>
                                    {product.caracteristics?.map((caracteristic, index) => (
                                        <li key={index}>
                                            <div className="property-detail-feature-list-item">
                                                <i className="flaticon-double-bed" />
                                                <div>
                                                    <h6>{caracteristic}</h6>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <h4 className="title-2">Depuis Notre Galerie</h4>
                            <div className="ltn__property-details-gallery mb-30">
                                <div className="row">
                                    {product.gallery?.map((image, index) => (
                                        <div className="col-md-6" key={index}>
                                            <a href={image} data-rel="lightcase:myCollection">
                                                <img className="mb-30" src={image} alt="Image" />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <h4 className="title-2">Équipements</h4>
                            <div className="property-details-amenities mb-60">
                                <div className="row">
                                    {/* Vérifie que product.equipments est défini et non vide */}
                                    {product.equipments && product.equipments.length > 0 ? (
                                        [...Array(Math.ceil(product.equipments.length / 4))].map((_, colIndex) => (
                                            <div className="col-lg-4 col-md-6" key={colIndex}>
                                                <div className="ltn__menu-widget">
                                                    <ul>
                                                        {product.equipments
                                                            .slice(colIndex * 4, colIndex * 4 + 4)
                                                            .map((equipment, index) => (
                                                                <li key={index}>
                                                                    <label className="checkbox-item">
                                                                        {equipment}
                                                                        <input type="checkbox" defaultChecked={true} disabled />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Aucun équipement disponible.</p>
                                    )}
                                </div>
                            </div>
                            <h4 className="title-2">Les transports à proximité</h4>
                            <div className="property-details-amenities mb-60">
                                <div className="row">
                                    {/* Vérifie que product.equipments est défini et non vide */}

                                    {product.metro && product.metro.length > 0 ? (
                                        product.metro?.map((metro, index) => {
                                            return (

                                                <img src={metro.image} alt={metro.name} style={{ width: '50px', height: '50px', margin: '5px' }} />
                                            )
                                        }))
                                        : (
                                            <span></span>

                                        )}
                                    {product.tramway && product.tramway.length > 0 ? (
                                        product.tramway?.map((tramway, index) => {
                                            return (
                                                <img src={tramway.image} alt={tramway.line} style={{ width: '50px', height: '50px', margin: '5px' }} />
                                            )
                                        }))
                                        : (
                                            <span></span>

                                        )}
                                    {product.rer && product.rer.length > 0 ? (
                                        product.rer?.map((rer, index) => {
                                            return (
                                                <img src={rer.image} alt={rer.line} style={{ width: '50px', height: '50px', margin: '5px' }} />
                                            )
                                        }))
                                        : (
                                            <span></span>
                                        )}
                                </div>
                            </div>


                            <h4 className="title-2">Emplacement</h4>
                            <div style={mapContainerStyles}>
                                {!coordinates ? (
                                    <div>Coordonnées de localisation non disponibles</div>
                                ) : (
                                    <div style={{ width: '100%', height: '400px' }}>
                                        <Map key={product._id} properties={[product]} />
                                    </div>
                                )}
                            </div>


                            {product.video && (
                                <>
                                    <h4 className="title-2">Vidéo de la Propriété</h4>
                                    <div className="video-container mb-60">
                                        <ReactPlayer
                                            url={product.video}
                                            width="100%"
                                            height="500px"
                                            controls={true}
                                        />
                                    </div>
                                </>
                            )}

                            <h4 className="title-2">Avis des Clients</h4>
                            <div className="product-ratting">
                                <ul>
                                    {Array.from({ length: Math.floor(product.reviews?.[0]?.rating || 0) }, (_, index) => (
                                        <li key={index}><a href="#"><i className="fas fa-star" /></a></li>
                                    ))}
                                    {(product.reviews?.[0]?.rating || 0) % 1 !== 0 && <li><a href="#"><i className="fas fa-star-half-alt" /></a></li>}
                                </ul>
                            </div>
                            <hr />

                            <div className="ltn__comment-area mb-30">
                                <div className="ltn__comment-inner">
                                    <ul>
                                        {reviewItems && reviewItems.length > 0 ? (
                                            reviewItems.map((review, index) => (
                                                <li key={index}>
                                                    <div className="ltn__comment-item clearfix">
                                                        {/* <div className="ltn__commenter-img">
                                                            <img src={publicUrl + review.image} alt="Image" />
                                                        </div> */}
                                                        <div className="ltn__commenter-comment">
                                                            <h6><a href="#">{review.fullname}</a></h6>
                                                            <div className="product-ratting">
                                                                <ul>
                                                                    {Array.from({ length: Math.floor(review.rating) }, (_, index) => (
                                                                        <li key={index}><a href="#"><i className="fas fa-star" /></a></li>
                                                                    ))}
                                                                    {review.rating % 1 !== 0 && <li><a href="#"><i className="fas fa-star-half-alt" /></a></li>}
                                                                </ul>
                                                            </div>
                                                            <p>{review.comment}</p>
                                                            <span className="ltn__comment-reply-btn">{review.date.split('T')[0]}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        ) : (
                                            <li>No reviews available.</li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <div className="ltn__comment-reply-area ltn__form-box mb-30">
                                <form onSubmit={handleCommentSubmit}>
                                    <h4>Ajouter un Avis</h4>
                                    <div>
                                        <h6>Votre Évaluation:</h6>
                                        <StarRating rating={rating} onRatingChange={setRating} />
                                    </div>
                                    <div>
                                        <textarea
                                            placeholder="Tapez vos commentaires...."
                                            name='comment'
                                            required
                                            onChange={handleCommentFormChange}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="fullname"
                                            placeholder="Tapez votre nom...."
                                            required
                                            onChange={handleCommentFormChange}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Tapez votre e-mail...."
                                            required
                                            onChange={handleCommentFormChange}
                                        />
                                    </div>
                                    <div>
                                        <button type="submit" style={{ backgroundColor: 'blue', color: 'white', marginTop: '15px', fontSize: '18px' }}>
                                            Soumettre
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <h4 className="title-2">Propriétés Connexes</h4>
                            <div className="row">
                                {nearbyProperties?.map((related, index) => (
                                    <div className="col-xl-6 col-sm-6 col-12 go-top" key={index}>
                                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                            <div className="product-img">
                                                <Link to={`/product-details/${related._id}`}><img src={related.image} alt="#" /></Link>
                                                <div className="real-estate-agent">
                                                    {/* <div className="agent-img">
                                                        <Link to="/team-details"><img src={publicUrl + product.agentImage} alt="#" /></Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="product-info">
                                                <div className="product-badge">
                                                    <ul>
                                                        <li className="sale-badg">{related.status}</li>
                                                    </ul>
                                                </div>
                                                <h2 className="product-title"><Link to={`/product-details/${related._id}`}>{related.title}</Link></h2>
                                                <div className="product-img-location">
                                                    <ul>
                                                        <li>
                                                            <Link to={`/product-details/${related._id}`}><i className="flaticon-pin" /> {related.adress}</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                                    <li><span>{related.bedrooms} </span>
                                                        Chambres
                                                    </li>
                                                    <li><span>{related.bathrooms} </span>
                                                        Salles de Bain
                                                    </li>
                                                    <li><span>{related.surface} </span>
                                                        m²
                                                    </li>
                                                </ul>
                                                <div className="product-hover-action">
                                                    <ul>
                                                        <li>
                                                            <a href="#" title="Aperçu Rapide" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
                                                                <i className="flaticon-expand" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" title="Liste de Souhaits" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
                                                                <i className="flaticon-heart-1" /></a>
                                                        </li>
                                                        <li>
                                                            <Link to="#" title="Comparer">
                                                                <i className="flaticon-add" />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-info-bottom">
                                                <div className="product-price">
                                                    <span>{related.priceLabel ? `€${related.price}${related.priceLabel}` : `€${related.price}`}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                            {/* Form Widget */}
                            <div className="widget ltn__form-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">Laissez un Message Pour Réserver</h4>
                                <form ref={form} onSubmit={sendEmail}>
                                    <input
                                        type="text"
                                        id="nom"
                                        name="nom"
                                        placeholder="Votre Nom*"
                                        required
                                    />
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Votre e-Mail*"
                                        required
                                    />
                                    <textarea
                                        id="message"
                                        name="message"
                                        placeholder="Écrivez un Message..."
                                        required
                                    ></textarea>
                                    <button type="submit" className="btn theme-btn-1">Envoyer le Message</button>
                                </form>
                            </div>
                            {/* Other widgets */}
                            <div className="widget ltn__top-rated-product-widget go-top">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">Produits Les Mieux Notés</h4>
                                <ul>
                                    {topRatedApartments && topRatedApartments.length > 0 ? (
                                        topRatedApartments.map((apartment, index) => (
                                            <li key={index}>
                                                <div className="top-rated-product-item clearfix">
                                                    <div className="top-rated-product-img">
                                                        <Link to={`/product-details/${apartment._id}`}>
                                                            <img src={apartment.image || 'default-image.jpg'} alt={apartment.title || 'Product Title'} />
                                                        </Link>
                                                    </div>
                                                    <div className="top-rated-product-info">
                                                        <div className="product-ratting" style={{ marginBottom: '10px' }}> {/* Ajoute un espacement avec marginBottom */}
                                                            <ul style={{ display: 'flex', gap: '5px' }}> {/* Ajoute un espace entre les étoiles */}
                                                                {Array.from({ length: Math.floor(apartment.averageRating) }, (_, index) => (
                                                                    <li key={index}><i className="fas fa-star" /></li>
                                                                ))}
                                                                {apartment.averageRating % 1 !== 0 && <li><i className="fas fa-star-half-alt" /></li>}
                                                            </ul>
                                                        </div>
                                                        <h6><Link to={`/product-details/${apartment._id}`}>{apartment.title || 'Titre non disponible'}</Link></h6>
                                                        <div className="product-price">
                                                            <span> {apartment.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 'Prix non disponible'} €</span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <p>No top-rated apartments available.</p>
                                    )}
                                </ul>
                            </div>


                            {/* Menu Widget (Category) */}
                            <div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2---">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">Catégories Principales</h4>
                                <ul className="go-top">
                                    <li><Link to="/blog-grid">Appartements <span>({propertyCounts.Appartement || 0})</span></Link></li>
                                    <li><Link to="/blog-grid">Studio<span>({propertyCounts.Studio || 0})</span></Link></li>
                                    <li><Link to="/blog-grid">Villas de Luxe <span>({propertyCounts.Villas || 0})</span></Link></li>
                                    <li><Link to="/blog-grid">Maison <span>({propertyCounts.Maison || 0})</span></Link></li>
                                </ul>
                            </div>
                            {/* Popular Product Widget */}
                            <div className="widget ltn__popular-product-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">Propriétés Populaires</h4>
                                <div className="row ltn__popular-product-widget-active slick-arrow-1">
                                    {popularProperties.length > 0 ? (
                                        popularProperties.map((property, index) => (
                                            <div className="col-12" key={index}>
                                                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                                    <div className="product-img">
                                                        <Link to={`/product-details/${property._id}`}>
                                                            <img src={property.image} alt="#" />
                                                        </Link>

                                                    </div>
                                                    <div className="product-info">
                                                        <div className="product-price">
                                                            <span> {property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 'Prix non disponible'} €</span>

                                                        </div>
                                                        <h2 className="product-title">
                                                            <Link to={`/product-details/${property._id}`}>
                                                                {property.title}
                                                            </Link>
                                                        </h2>
                                                        <div className="product-img-location">
                                                            <ul>
                                                                <li>
                                                                    <Link to={`/product-details/${property._id}`}>
                                                                        <i className="flaticon-pin" /> {property.location}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                                            <li><span>{property.bedrooms} </span>Chambres</li>
                                                            <li><span>{property.bathrooms} </span>Salles de Bain</li>
                                                            <li><span>{property.surface} </span>m²</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No popular properties available.</p>
                                    )}
                                </div>
                            </div>
                            {/* Social Media Widget */}
                            <div className="widget ltn__social-media-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">Suivez-nous sur les réseaux sociaux</h4>
                                <div className="ltn__social-media-2">
                                    <ul>
                                        <li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                                        <li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
                                        <li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
                                        <li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>

                            {/* Tagcloud Widget */}

                            {/* Banner Widget */}
                            <div className="widget ltn__banner-widget d-none go-top">
                                <Link to="/shop"><img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" /></Link>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopDetails;