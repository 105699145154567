import React, { useState } from 'react';

const StarRating = ({ rating, onRatingChange }) => {
    const [hover, setHover] = useState(0);

    const starStyle = {
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        fontSize: '24px',
        padding: '0 2px'
    };

    return (
        <div>
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <button
                        type="button"
                        key={index}
                        style={{
                            ...starStyle,
                            color: index <= (hover || rating) ? '#ffc107' : '#e4e5e9'
                        }}
                        onClick={() => onRatingChange(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        &#9733;
                    </button>
                );
            })}
        </div>
    );
};

export default StarRating;