import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AProposV2 = () => {
    let publicUrl = process.env.PUBLIC_URL + '/';

    const sectionRef = useRef(null);
    const contentRef = useRef(null);
    const imagesRef = useRef(null);
    const imagePrincipaleRef = useRef(null);

    useEffect(() => {
        const section = sectionRef.current;
        const content = contentRef.current;
        const images = imagesRef.current;
        const imagePrincipale = imagePrincipaleRef.current;

        // Create a single ScrollTrigger for the entire section
        ScrollTrigger.create({
            trigger: section,
            start: "top 80%",
            end: "bottom 20%",
            onEnter: () => {
                // Animate content
                gsap.to(content, {
                    opacity: 1,
                    y: 0,
                    duration: 1.5,
                    stagger: 0.1,
                    ease: "power2.out"
                });

                // Animate images
                gsap.to(images.children, {
                    opacity: 1,
                    scale: 1,
                    duration: 1.5,
                    stagger: 0.1,
                    ease: "back.out(1.7)"
                });

                // Animate main image
                gsap.to(imagePrincipale, {
                    opacity: 1,
                    x: 0,
                    duration: 1.5,
                    ease: "power2.out"
                });
            },
            onLeaveBack: () => {
                // Reset animations when scrolling back up
                gsap.set(content, { opacity: 0, y: 30 });
                gsap.set(images.children, { opacity: 0, scale: 0.8 });
                gsap.set(imagePrincipale, { opacity: 0, x: 50 });
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <div ref={sectionRef} className="ltn__about-us-area pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div ref={contentRef} className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-30">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">À propos de nous</h6>
                                <h1 className="section-title">Nous vous proposons de trouver votre bien</h1>
                                <p>Houzez vous permet de concevoir des panneaux illimités et des formulaires immobiliers personnalisés pour capturer des prospects et conserver un enregistrement de toutes les informations.</p>
                            </div>
                            <ul className="ltn__list-item-1 ltn__list-item-1-before clearfix">
                                <li>Concerts de musique live à Luviana</li>
                                <li>Notre visite en bateau SecretIsland est juste pour vous</li>
                                <li>Concerts de musique live à Luviana</li>
                                <li>Concerts de musique live à Luviana</li>
                            </ul>
                            <ul className="ltn__list-item-2 ltn__list-item-2-before ltn__flat-info">
                                <li><span>3 <i className="flaticon-bed" /></span>
                                    Chambres
                                </li>
                                <li><span>2 <i className="flaticon-clean" /></span>
                                    Salles de bain
                                </li>
                                <li><span>2 <i className="flaticon-car" /></span>
                                    Parking
                                </li>
                                <li><span>3450 <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
                                    pieds carrés
                                </li>
                            </ul>
                            <ul ref={imagesRef} className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
                                <li>
                                    <a href={publicUrl + "assets/img/banner/bannerap1.jpg"} data-rel="lightcase:myCollection">
                                        <img src={publicUrl + "assets/img/banner/bannerap1.jpg"} alt="Image" />
                                    </a>
                                </li>
                                <li>
                                    <a href={publicUrl + "assets/img/banner/bannerap2.jpg"} data-rel="lightcase:myCollection">
                                        <img src={publicUrl + "assets/img/banner/bannerap2.jpg"} alt="Image" />
                                    </a>
                                </li>
                                <li>
                                    <a href={publicUrl + "assets/img/banner/bannerap5.jpg"} data-rel="lightcase:myCollection">
                                        <img src={publicUrl + "assets/img/banner/bannerap5.jpg"} alt="Image" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div ref={imagePrincipaleRef} className="about-us-img-wrap about-img-right">
                            <img src={publicUrl + "assets/img/banner/bannerap.jpg"} alt="Image À propos de nous" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AProposV2;