import { createSlice } from "@reduxjs/toolkit";
import { actLogin } from './AuthActions';

const initialState = {
    auth: null,
    accessToken: null,
    loading: "idle",
    error: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        resetUI: (state) => {
            state.loading = "idle";
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(actLogin.pending, (state) => {
                state.loading = "pending";
                state.error = null;
            })
            .addCase(actLogin.fulfilled, (state, action) => {
                state.loading = "succeeded";
                state.message = action.payload.message;
                state.accessToken = action.payload.token;
            })
            .addCase(actLogin.rejected, (state, action) => {
                state.loading = "failed";
                if (action.payload && typeof action.payload == "string") {
                    state.error = action.payload;
                }
            })
    },
});

export { actLogin };
export const { resetUI } = authSlice.actions;
export default authSlice.reducer;