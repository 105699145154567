import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWxlbDIwMDAiLCJhIjoiY2x3ZmtndmQzMHFmYTJqcWp4bmYxdWhzdCJ9.Z1nxxSYSaNdQ4EWSkof2iA';

const Map = ({ properties }) => {
    const mapRef = useRef(null);
    const mapInstance = useRef(null);
    const [selectedMarker, setSelectedMarker] = useState(null);

    const propertiesArray = Array.isArray(properties) ? properties : [properties];

    useEffect(() => {
        if (!propertiesArray || propertiesArray.length === 0 || !propertiesArray[0].geoLocation) {
            console.error("No valid properties data received or missing geoLocation");
            return;
        }

        const loadMap = () => {
            mapInstance.current = new mapboxgl.Map({
                container: mapRef.current,
                style: 'mapbox://styles/mapbox/outdoors-v12',
                center: propertiesArray[0].geoLocation.coordinates,
                zoom: 10,
            });

            mapInstance.current.on('load', () => {
                mapInstance.current.loadImage(
                    'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                    (error, image) => {
                        if (error) throw error;
                        mapInstance.current.addImage('custom-marker', image);
                    }
                );

                mapInstance.current.addSource('properties', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: propertiesArray.map(property => ({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: property.geoLocation.coordinates
                            },
                            properties: property
                        }))
                    },
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50
                });

                mapInstance.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'properties',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#51bbd6',
                            100,
                            '#f1f075',
                            750,
                            '#f28cb1'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40
                        ]
                    }
                });

                mapInstance.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'properties',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12
                    }
                });

                mapInstance.current.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'properties',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'icon-image': 'custom-marker',
                        'icon-size': 0.5
                    }
                });

                mapInstance.current.on('click', 'clusters', (e) => {
                    const features = mapInstance.current.queryRenderedFeatures(e.point, { layers: ['clusters'] });
                    const clusterId = features[0].properties.cluster_id;
                    mapInstance.current.getSource('properties').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return;
                            mapInstance.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                });

                mapInstance.current.on('click', 'unclustered-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const property = e.features[0].properties;

                    const popup = new mapboxgl.Popup({
                        closeButton: false,
                        maxWidth: '300px'
                    })
                        .setLngLat(coordinates)
                        .setHTML(`
                        <div style="
                            width: 280px;
                            background-color: white;
                            border-radius: 8px;
                            box-shadow: 0 2px 10px rgba(0,0,0,0.1);
                            font-family: Arial, sans-serif;
                            overflow: hidden;
                        ">
                            <img src="${property.image}" alt="${property.title}" style="
                                width: 100%;
                                height: 160px;
                                object-fit: cover;
                            "/>
                            <div style="padding: 15px;">
                                <h3 style="
                                    margin: 0 0 10px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #333;
                                ">${property.title}</h3>
                                <p style="
                                    margin: 0 0 5px;
                                    font-size: 14px;
                                    color: #666;
                                ">${property.adress}</p>
                                <p style="
                                    margin: 5px 0 15px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #333;
                                ">dès ${property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€</p>
                                <button onclick="window.location.href='/product-details/${property._id}'" style="
                                    width: 100%;
                                    padding: 10px;
                                    background-color: black;
                                    color: white;
                                    border: none;
                                    border-radius: 4px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    cursor: pointer;
                                    transition: background-color 0.3s ease;
                                ">
                                    En savoir +
                                </button>
                            </div>
                        </div>
                    `)
                        .addTo(mapInstance.current);
                    setSelectedMarker(popup);
                });

                mapInstance.current.on('mouseenter', 'clusters', () => {
                    mapInstance.current.getCanvas().style.cursor = 'pointer';
                });
                mapInstance.current.on('mouseleave', 'clusters', () => {
                    mapInstance.current.getCanvas().style.cursor = '';
                });

                const bounds = new mapboxgl.LngLatBounds();
                propertiesArray.forEach(property => {
                    bounds.extend(property.geoLocation.coordinates);
                });
                mapInstance.current.fitBounds(bounds, { padding: 50, maxZoom: 14 });
            });
        };

        if (!mapInstance.current) {
            loadMap();
        } else {
            const source = mapInstance.current.getSource('properties');
            if (source) {
                source.setData({
                    type: 'FeatureCollection',
                    features: propertiesArray.map(property => ({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: property.geoLocation.coordinates
                        },
                        properties: property
                    }))
                });
            }
        }

        // Enhanced cleanup function
        return () => {
            if (selectedMarker) {
                selectedMarker.remove();
            }
            if (mapInstance.current) {
                // Remove event listeners
                mapInstance.current.off('click', 'clusters');
                mapInstance.current.off('click', 'unclustered-point');
                mapInstance.current.off('mouseenter', 'clusters');
                mapInstance.current.off('mouseleave', 'clusters');

                // Remove layers
                if (mapInstance.current.getLayer('clusters')) {
                    mapInstance.current.removeLayer('clusters');
                }
                if (mapInstance.current.getLayer('cluster-count')) {
                    mapInstance.current.removeLayer('cluster-count');
                }
                if (mapInstance.current.getLayer('unclustered-point')) {
                    mapInstance.current.removeLayer('unclustered-point');
                }

                // Remove source
                if (mapInstance.current.getSource('properties')) {
                    mapInstance.current.removeSource('properties');
                }

                // Remove the map
                mapInstance.current.remove();
                mapInstance.current = null;
            }
        };
    }, [propertiesArray]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            borderRadius: '12px',
            overflow: 'hidden',
            position: 'relative',
        }} ref={mapRef}></div>
    );
};

export default Map;