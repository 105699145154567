import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

class CaracteristiquesV1 extends Component {

    componentDidMount() {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let classePersonnalisee = this.props.customClass ? this.props.customClass : '';

        return (
            <div className={classePersonnalisee}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nos Services</h6>
                                <h1 className="section-title">Notre Principale Priorité</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row ltn__custom-gutter--- justify-content-center go-top">
                        <div className="col-lg-4 col-sm-6 col-12" data-aos="fade-up"> {/* Add AOS attribute */}
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1 card-animation">
                                <div className="ltn__feature-icon">
                                    <img src={publicUrl + "assets/img/icons/icon-img/21.png"} alt="#" />
                                </div>
                                <div className="ltn__feature-info">
                                    <h3><Link to="/service-details">Acheter</Link></h3>
                                    <p>Plus de 1 million de maisons en vente sont disponibles sur le site web, nous pouvons vous mettre en relation avec une maison que vous voudrez appeler chez vous.</p>
                                    <Link style={{ marginTop: '65px' }} className="ltn__service-btn" to="/shop">Trouver une maison <i className="flaticon-right-arrow" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12" data-aos="fade-up" data-aos-delay="200"> {/* Add AOS attribute */}
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1 card-animation active">
                                <div className="ltn__feature-icon">
                                    <img src={publicUrl + "assets/img/icons/icon-img/23.png"} alt="#" />
                                </div>
                                <div className="ltn__feature-info">
                                    <h3><Link to="/service-details">Accompagner</Link></h3>
                                    <p>Plus de 1 million de maisons en vente sont disponibles sur le site web, nous pouvons vous mettre en relation avec une maison que vous voudrez appeler chez vous.</p>
                                    <Link className="ltn__service-btn" to="/shop">Trouver une maison <i className="flaticon-right-arrow" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12" data-aos="fade-up" data-aos-delay="400"> {/* Add AOS attribute */}
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1 card-animation">
                                <div className="ltn__feature-icon">
                                    <img src={publicUrl + "assets/img/icons/icon-img/22.png"} alt="#" />
                                </div>
                                <div className="ltn__feature-info">
                                    <h3><Link to="/service-details">Financier</Link></h3>
                                    <p>Plus de 1 million de maisons en vente sont disponibles sur le site web, nous pouvons vous mettre en relation avec une maison que vous voudrez appeler chez vous.</p>
                                    <Link style={{ marginTop: '65px' }} className="ltn__service-btn" to="/shop">Trouver une maison <i className="flaticon-right-arrow" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CaracteristiquesV1;