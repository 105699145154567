import { createSlice } from '@reduxjs/toolkit';
import { submitActualites, getActualites, getRecentActualites, getActualiteById, actDeleteActualite, actUpdateActualite } from './ActualiteActions';

const actualitesSlice = createSlice({
  name: 'actualites',
  initialState: {
    items: [],
    recentItems: [],
    status: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitActualites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitActualites.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(submitActualites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

    builder
      .addCase(getActualites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getActualites.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getActualites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

    builder
      .addCase(getRecentActualites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRecentActualites.fulfilled, (state, action) => {
        state.recentItems = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getRecentActualites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

    builder
      .addCase(getActualiteById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getActualiteById.fulfilled, (state, action) => {
        const index = state.items.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        } else {
          state.items.push(action.payload);
        }
        state.status = 'succeeded';
      })
      .addCase(getActualiteById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Delete Actualite
      .addCase(actDeleteActualite.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(actDeleteActualite.fulfilled, (state, action) => {
        state.items = state.items.filter(item => item._id !== action.payload);
        state.status = 'succeeded';
      })
      .addCase(actDeleteActualite.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Update Actualite
      .addCase(actUpdateActualite.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(actUpdateActualite.fulfilled, (state, action) => {
        const index = state.items.findIndex(item => item._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.status = 'succeeded';
      })
      .addCase(actUpdateActualite.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export const actualitesReducer = actualitesSlice.reducer;
export { submitActualites, getActualites, getRecentActualites, getActualiteById, actUpdateActualite, actDeleteActualite };